<template>
  <PltuForm mode="Tambah" module="PLTU"> </PltuForm>
</template>

<script>
import PltuForm from './form';

const PltuAdd = {
  name: 'PltuAdd',
  components: { PltuForm },
};

export default PltuAdd;
</script>
